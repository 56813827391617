import React, { ChangeEvent, MouseEventHandler, useContext, useEffect, useState } from "react"
import { getAuth } from "firebase/auth";
import { GeoPoint, Timestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";

import MenuOverlay from "../../components/navigation/MenuOverlay";
import MenuDropDown from "../../components/dropDown/MenuDropDown";

import "react-datepicker/dist/react-datepicker.css";


import { lowercaseStringBuilderList } from "../../utils/lowercaseStringBuilder";

import GoogleAutoCompleteDropDown from "../../components/GoogleAutoCompleteDropDown";
import PlainButton from "../../components/buttons/PlainButton";
import { addPrayer } from "../../data/repository/firebasePrayers";
import FormFieldsContainer from "../../components/FormFieldsContainer";
import { toast } from "sonner";
import PrayerTimesByCoordinate from "../../models/PrayerTimesByCoordinate";
import FooterJanaza from "../../components/commons/FooterJanaza";
import HeaderMenu from "../../components/navigation/headerMenu/HeaderMenu";
import { LoadingOverlay } from "../../components/commons/LoadingOverlay";
import { DatePicker, LocalizationProvider, StaticDatePicker, StaticTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from "dayjs";
import 'dayjs/locale/fr';
import useOnclickOutside from "react-cool-onclickoutside";
import { format } from "path";

const defaultFormFields = {
    category: '',
    phone: '',
    name: '',
    sexe: '',
    address: '',
    date: '',
    prayName: '',
    hour: '',
    addressConvoi: '',
    moreInfos: '',
    convoi: '',
    hourConvoy: '',
    location: new GeoPoint(0, 0),
    locationConvoi: new GeoPoint(0, 0),
}

const defaultFormFieldsError = {
    category: '',
    phone: '',
    name: '',
    sexe: '',
    address: '',
    date: '',
    prayName: '',
    hour: '',
    addressConvoi: '',
    moreInfos: '',
    convoi: '',
    hourConvoy: '',
    location: new GeoPoint(0, 0),
    locationConvoi: new GeoPoint(0, 0),
}

const AddPrayer: React.FunctionComponent = (props) => {

    const auth = getAuth();
    const navigate = useNavigate();

    const googleApiKey = "AIzaSyB4uoN_f8_foBcvyY_pVJs3n59bxRFbJbI";

    const [isMenuOpen, setMenuOpen] = useState(false)

    const [timeDialogOpen, setTimeDialogOpen] = useState(false)
    const [convoyTimeDialogOpen, setConvoyTimeDialogOpen] = useState(false)
    const [dateDialogOpen, setDateDialogOpen] = useState(false)

    const [formFields, setFormFields] = useState(defaultFormFields)
    const [formFieldsError, setFormFieldsError] = useState(defaultFormFieldsError)
    const {
        phone,
        name,
        sexe,
        address,
        date,
        prayName,
        hour,
        addressConvoi,
        moreInfos,
        convoi,
        hourConvoy,
        location,
        locationConvoi
    } = formFields;

    const [showOrganisationDropDown, setOrganisationShowDropDown] = useState(false);
    const [showSexeDropDown, setSexeShowDropDown] = useState(false);
    const [showAfterPrayerDropDown, setShowAfterPrayerDropDown] = useState(false);
    const [showConvoiDropDown, setShowConvoiDropDown] = useState(false);

    const [isSending, setSending] = useState(false);

    const { currentUser, signOut } = useContext(AuthContext);
    const [signingOut, setSigningOut] = useState(false);

    const [resetAddress, setResetAddress] = useState(false)

    const cities = () => {return ["Particulier", "Mosquées", "Ecole", "Pompe funèbre", "Laveuse", "Laveur", "Association", "Entreprise", "Autre"];};
    const sexes = () => {return ["Homme", "Femme", "Je ne sais pas"];};
    const afterPrayers = () => {return ["Fajr", "Dhuhr", "'Asr", "Maghreb", 'Ichâ'];};
    const convois = () => {return ["Oui", "Non", "Je ne sais pas"];};


    const handleTimeDialog = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation()
        setTimeDialogOpen(!timeDialogOpen)
    }

    const handleConvoyTimeDialog = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation()
        setConvoyTimeDialogOpen(!convoyTimeDialogOpen)
    }

    const handleDateDialog = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation()
        setDateDialogOpen(!dateDialogOpen)
    }

    const handleTimeDialogChange = (value: Dayjs | null) => {
        const hour = value?.hour();
        const minute = value?.minute();

        if (hour != null && minute != null) {
            let finalHour = hour.toString();
            let finalMinute = minute.toString();

            if (hour <= 9) finalHour = 0 + finalHour;
            if (minute <= 9) finalMinute = 0 + finalMinute

            setFormFields({
                ...formFields,
                hour: finalHour + ":" + finalMinute
            })
        }
    }

    const handleConvoyTimeDialogChange = (value: Dayjs | null) => {
        const hour = value?.hour();
        const minute = value?.minute();

        if (hour != null && minute != null) {
            let finalHour = hour.toString();
            let finalMinute = minute.toString();

            if (hour <= 9) finalHour = 0 + finalHour;
            if (minute <= 9) finalMinute = 0 + finalMinute

            setFormFields({
                ...formFields,
                hourConvoy: finalHour + ":" + finalMinute
            })
        }
    }

    const handleDateDialogChange = (value: Dayjs | null) => {
        const day = value?.date()
        const month = (value?.month() ?? 0) + 1
        const year = value?.year()

        if (day != null && month != null && year != null) {
            let finalDay = day.toString();
            let finalMonth = month.toString();
            const finalYear = year.toString();

            if (day <= 9) finalDay = 0 + finalDay
            if (month <= 9) finalMonth = 0 + finalMonth

            setFormFields({
                ...formFields,
                date: finalDay + "/" + finalMonth + "/" + finalYear
            })
        }
    }

    const handleSignOut = async () => {
        setSigningOut(true)

        try {
            await timeout(1000)
            await signOut()
            setSigningOut(false)
            toast.error("Vous êtes désormais déconnecté.")
            setMenuOpen(false)
        } catch (error) {
            setSigningOut(false)
            toast.error("Une erreur est survenue.")
            console.log(error)
        }
    }

    const handleSubmit = async () => {

        setSending(true);

        if (!currentUser) return;

        if (name.length === 0) {
            setFormFieldsError({
                ...defaultFormFieldsError,
                name: "Veuillez remplir le nom",
            });
            toast.error("Il y a une erreur dans le formulaire");
            setSending(false);
            return;
        }

        if (sexe.length === 0) {
            setFormFieldsError({
                ...defaultFormFieldsError,
                sexe: "Veuillez choisir le sexe",
            });
            toast.error("Il y a une erreur dans le formulaire");
            setSending(false);
            return;
        }

        if (address.length === 0) {
            setFormFieldsError({
                ...defaultFormFieldsError,
                address: "Veuillez entrez une adresse valide",
            });
            toast.error("Il y a une erreur dans le formulaire");
            setSending(false);
            return;
        }

        if (date.length === 0) {
            setFormFieldsError({
                ...defaultFormFieldsError,
                date: "Veuillez entrez une date valide",
            });
            toast.error("Il y a une erreur dans le formulaire");
            setSending(false);
            return;
        }

        if (hour.length === 0) {
            setFormFieldsError({
                ...defaultFormFieldsError,
                hour: "Veuillez entrez l'heure de la Janaza",
            });
            toast.error("Il y a une erreur dans le formulaire");
            setSending(false);
            return;
        }

        if (convoi.length === 0) {
            setFormFieldsError({
                ...defaultFormFieldsError,
                convoi: "Veuillez faire un choix",
            });
            toast.error("Il y a une erreur dans le formulaire");
            setSending(false);
            return;
        }

        if (convoi === "Oui" ? addressConvoi.length === 0 : false) {
            setFormFieldsError({
                ...defaultFormFieldsError,
                addressConvoi: "Veuillez entrez une adresse valide",
            });
            toast.error("Il y a une erreur dans le formulaire");
            setSending(false);
            return;
        }

        try {
            const [day, month, year] = date.split("/") 
            const [hours, min] = hour.split(":")

            await addPrayer({
                user_id: currentUser?.uid,
                category: "Particulier", // TO FIX WITH USER ORGANISATION CHOOSE ON SIGN UP
                phone: phone,
                name: name,
                nameLowercaseArray: lowercaseStringBuilderList(name),
                sexe: sexe,
                date: date,
                hour: hour,
                address: address,
                address_convoi: addressConvoi,
                more_infos: moreInfos,
                convoi: convoi,
                hourConvoy: hourConvoy,
                pray_name: prayName,
                view_count: null,
                user_views: null,
                location: location,
                location_convoi: locationConvoi,
                added_at: Timestamp.now(),
                begin_date: new Date(+year, +month - 1, +day, +hours + 1, +min, 0).toISOString(),
                is_updated: false,
                updated_at: null
            }).then( async (res) => {
                await timeout(500);
                setSending(false);
                toast.success("Barak'Allahu fikoum, la Salat Janaza a bien été ajouté !");
                console.log("Success janaza added");
                navigate("/prayers");
            });
        } catch (error) {
            setSending(false);
            toast.error("Impossible d'ajouter la Janaza pour le moment, veuillez réessayer");
            console.log("Adding prayer failed", error);
        }
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target
        setFormFieldsError({...defaultFormFieldsError})
        setFormFields({...formFields, [name]: value })
    }

    const getPrayTimeBasedOnAddress = async () => {

        if (address.length === 0) {
            setFormFieldsError({
                ...defaultFormFieldsError,
                address: "Veuillez entrez une adresse valide",
            });
            toast.error("Impossible de récupérer l'heure pour le moment");
            setSending(false);
            return;
        }

        if (date.length === 0) {
            setFormFieldsError({
                ...defaultFormFieldsError,
                date: "Veuillez entrez une date valide",
            });
            toast.error("Impossible de récupérer l'heure pour le moment");
            setSending(false);
            return;
        }

        if (prayName.length === 0) {
            setFormFieldsError({
                ...defaultFormFieldsError,
                prayName: "Veuillez choisir le moment de la prière",
            });
            toast.error("Impossible de récupérer l'heure pour le moment");
            setSending(false);
            return;
        }

        setFormFieldsError({
            ...defaultFormFieldsError
        });

        try {
            const [year, month, day] = date.split("/")

            const promise = () => new Promise((resolve) => setTimeout(() => resolve({ name: 'Sonner' }), 0));

            const fetchPrayTime = await fetch(`http://api.aladhan.com/v1/timings/${day}-${month}-${year}?latitude=${location.latitude}&longitude=${location.longitude}&method=12`, { method: "GET"});

            const prayTime: PrayerTimesByCoordinate = await fetchPrayTime.json();

            switch (prayName) {
                case "Fajr":
                    setFormFields({
                        ...formFields,
                        hour: prayTime.data.timings.Fajr
                    });
                    break;
                case "Dhuhr":
                    setFormFields({
                        ...formFields,
                        hour: prayTime.data.timings.Dhuhr
                    });
                    break;
                case "'Asr":
                    setFormFields({
                        ...formFields,
                        hour: prayTime.data.timings.Asr
                    });
                    break;
                case "Maghreb":
                    setFormFields({
                        ...formFields,
                        hour: prayTime.data.timings.Maghrib
                    });
                    break;
                case "Ichâ":
                    setFormFields({
                        ...formFields,
                        hour: prayTime.data.timings.Isha
                    });
                    break;
            }

            toast.promise(promise, {
                loading: 'Récupération en cours...',
                success: () => {
                    return `L'heure a bien été récupérée.`;
                },
                error: 'Récupération impossible pour le moment.',
            });

            console.log(fetchPrayTime.body);
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        setFormFields({...formFields, addressConvoi: '', hourConvoy: ''})
    }, [convoi])

    useEffect(() => {
        if (isMenuOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [isMenuOpen, signingOut]);

    return (
        <div>
            <div className="bg-white w-full min-h-screen font-roboto 2xl:m-auto relative">
                <section className="flex flex-col h-full w-full overflow-hidden items-center bg-blue_sky"> {/* First Section */}
                    <HeaderMenu
                        classNames=""
                        currentUser={currentUser}
                        isBackButtonVisible={true}
                        isHamburgerVisible={true}
                        isMenuOpen={isMenuOpen}
                        isSeeJanazaButtonVisible={true}
                        setMenuOpen={setMenuOpen}
                        handleSignOut={handleSignOut}
                    />
                    <div className="flex relative bg-transparent items flex-col min-h-screen max-w-screen-lg self-center h-fit w-full pb-[30px] px-4 pt-32 lg:static lg:w-11/12 lg:px-0 lg:pt-40 lg:pb-[40px]"> {/* Body first section */}
                        {/* <SecondaryTitle text="Ajouter une Janaza" className="w-fit mb-8 self-center text-3xl"/> */}
                        <h2 className={`w-fit mb-12 self-center text-3xl text-primary_color_10 font-semibold lg:text-4xl`}>Ajouter une Janaza</h2>
                        <form>
                            <FormFieldsContainer
                                className="mb-4"
                                label="Infos"
                            >
                                <div className="flex flex-col gap-3 lg:flex-row lg:gap-2">

                                    {/* Organisation/Category DropDown */}

                                    {/* <div className="flex flex-col lg:flex-1">
                                        <label className="text-base font-normal text-primary_color mb-1">Qui êtes-vous ? *</label>
                                        <MenuDropDown
                                            className=""
                                            placeholder="Choisir une organisation"
                                            isDropDownClicked={showOrganisationDropDown}
                                            valueList={cities()}
                                            query={category}
                                            setShowDropDown={(value: boolean) => setOrganisationShowDropDown(value)}
                                            valueSelection={(value: string) => setFormFields({...formFields, category: value })}
                                        />
                                        <p className="font-regular ps-2 text-sm text-red pt-0.5">{formFieldsError.category}</p>
                                    </div> */}

                                    <div className="flex flex-col lg:flex-1">
                                        <label className="text-base font-normal text-primary_color mb-1">{`Téléphone (pour plus d'infos)`}</label>
                                        <input
                                            placeholder="Entrez un numéro"
                                            type="text"
                                            name="phone"
                                            pattern="[0-9*]"
                                            value={phone}
                                            onInput={handleChange}
                                            className={`${phone.length === 0 ? "border-grey_40" : "border-primary_color"} text-base py-3 box-border placeholder-grey_light bg-grey_30 outline-none border-2 border-grey_40 focus:border-primary_color px-5 transition-colors rounded-lg`}
                                        />
                                    </div>
                                </div>
                            </FormFieldsContainer>
                            
                            <FormFieldsContainer
                                className="mb-4"
                                label="Défunt"
                            >
                                <div className="flex flex-col gap-3 lg:flex-row lg:gap-2">
                                    <div className="flex flex-col lg:flex-1">
                                        <label className="text-base font-normal text-primary_color mb-1">Nom complet du défunt *</label>
                                        <input
                                            required
                                            placeholder="Entrez un nom"
                                            type="text"
                                            name="name"
                                            value={name}
                                            onChange={handleChange}
                                            className={`${name.length === 0 ? "border-grey_40" : "border-primary_color"} text-base py-3 box-border placeholder-grey_light bg-grey_30 outline-none focus:border-primary_color border-2 px-5 transition-colors rounded-lg`}
                                        />
                                        <p className="font-regular ps-2 text-sm text-red pt-0.5">{formFieldsError.name}</p>
                                    </div>

                                    {/* Sexe DropDown */}

                                    <div className="flex flex-col lg:flex-1">
                                        <label className="text-base font-normal text-primary_color mb-1">Sexe *</label>
                                        <MenuDropDown
                                            className=""
                                            placeholder="Sexe du défunt"
                                            isDropDownClicked={showSexeDropDown}
                                            valueList={sexes()}
                                            query={sexe}
                                            setShowDropDown={(value: boolean) => setSexeShowDropDown(value)}
                                            valueSelection={(value: string) => setFormFields({...formFields, sexe: value })}
                                        />
                                        <p className="font-regular ps-2 text-sm text-red pt-0.5">{formFieldsError.sexe}</p>
                                    </div>
                                </div>
                            </FormFieldsContainer>
                            
                            <FormFieldsContainer
                                className="mb-4"
                                label="Horaires et Lieu"
                            >
                                <div className="flex flex-col mb-3">
                                    <label className="text-base font-regular text-primary_color mb-1">Adresse/Lieu de la Janaza *</label>
                                    <GoogleAutoCompleteDropDown
                                        className={``}
                                        reset={resetAddress}
                                        placeholder="Choisir un(e) adresse/lieu"
                                        onHandleSelectAddress={(address: string, location: GeoPoint) => setFormFields({...formFields, address: address, location: location})}
                                    />
                                    <p className="font-regular ps-2 text-sm text-red pt-0.5">{formFieldsError.address}</p>
                                </div>
                                <div className="flex flex-col gap-3 lg:flex-row lg:gap-2">
                                    <div className="flex flex-col lg:flex-1">
                                        <label className="text-base font-regular text-primary_color mb-1">Date *</label>
                                        <input
                                            required
                                            placeholder="Choisir une date"
                                            type="text"
                                            readOnly
                                            name="date"
                                            value={date}
                                            onClick={handleDateDialog}
                                            className={`${date.length == 0 ? "text-grey_light border-grey_40" : "text-primary_color_10 border-primary_color"} cursor-pointer text-base min-w-full w-full py-0 h-[52px] box-border ps-5 pe-5 placeholder-grey_light bg-grey_30 border-grey_40 outline-none focus:border-primary_color border-2 px-5 transition-colors rounded-lg lg:flex-1 lg:py-0 lg:px-5`}
                                        />
                                        <p className="font-regular ps-2 text-sm text-red pt-0.5">{formFieldsError.date}</p>
                                    </div>

                                    {/* AfterPrayer DropDown */}

                                    <div className="flex flex-col lg:flex-1">
                                        <label className="text-base font-normal text-primary_color mb-1">Après quel prière ?</label>
                                        <MenuDropDown
                                            className=""
                                            placeholder="Choisir une prière"
                                            isDropDownClicked={showAfterPrayerDropDown}
                                            valueList={afterPrayers()}
                                            query={prayName}
                                            setShowDropDown={(value: boolean) => setShowAfterPrayerDropDown(value)}
                                            valueSelection={(value: string) => setFormFields({...formFields, prayName: value })}
                                        />
                                        <p className="font-regular ps-2 text-sm text-red pt-0.5">{formFieldsError.prayName}</p>
                                    </div>
                                    
                                    <div className="flex flex-col lg:flex-1">
                                        <label className="text-base font-regular text-primary_color mb-1">Heure *</label>
                                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <MobileTimePicker className={`${hour.length === 0 ? "text-grey_light border-grey_40" : "text-primary_color_10 border-primary_color"} text-base py-3 min-w-full h-fit w-full box-border placeholder-grey_light bg-grey_30 border-grey_40 outline-none focus:border-primary_color border-2 px-5 transition-colors rounded-lg lg:flex-1`}/>
                                        </LocalizationProvider> */}
                                        
                                        <input
                                            required
                                            placeholder="Choisir une heure"
                                            type="text"
                                            readOnly
                                            name="hour"
                                            value={hour}
                                            onClick={handleTimeDialog}
                                            className={`${hour.length === 0 ? "text-grey_light border-grey_40" : "text-primary_color_10 border-primary_color"} cursor-pointer text-base py-3 min-w-full w-full box-border placeholder-grey_light bg-grey_30 border-grey_40 outline-none focus:border-primary_color border-2 px-5 transition-colors rounded-lg lg:flex-1`}
                                        />
                                        <p className="font-regular ps-2 text-sm text-red pt-0.5">{formFieldsError.hour}</p>
                                    </div>
                                    
                                </div>

                                <button
                                    type="button"
                                    className="text-center text-sm font-normal self-center w-full mt-2 py-2 px-4 rounded-xl transition-colors bg-grey_30 text-primary_color_10 hover:bg-primary_color_90"
                                    onClick={getPrayTimeBasedOnAddress}
                                >
                                    <span className="text-primary_color">Cliquez ici</span>{` pour récupérer l'heure de la prière basée sur l'adresse.`}
                                </button>
                            </FormFieldsContainer>
                            <FormFieldsContainer
                                className="mb-4"
                                label="Plus d'infos et convoi"
                            >
                                <div className="flex flex-col gap-3 lg:flex-row lg:gap-2">
                                    <div className="flex flex-col lg:flex-1">
                                        <label className="text-base font-regular text-primary_color mb-1">Infos supplémentaires</label>
                                        <input
                                            placeholder="Entrez les infos"
                                            type="text"
                                            name="moreInfos"
                                            maxLength={200}
                                            value={moreInfos}
                                            onChange={handleChange}
                                            className={`${moreInfos.length === 0 ? "border-grey_40" : "border-primary_color"} text-base py-3 box-border placeholder-grey_light bg-grey_30 border-grey_40 outline-none focus:border-primary_color border-2 px-5 transition-colors rounded-lg`}
                                        />
                                        <p className="font-regular ps-2 text-sm text-red pt-0.5">{formFieldsError.moreInfos}</p>
                                    </div>

                                    {/* Convoi DropDown */}

                                    <div className="flex flex-col lg:flex-1">
                                        <label className="text-base font-regular text-primary_color mb-1">Convoi *</label>
                                        <MenuDropDown
                                            noSpaceAvailable={true}
                                            className=""
                                            placeholder="Choisir un convoi"
                                            isDropDownClicked={showConvoiDropDown}
                                            valueList={convois()}
                                            query={convoi}
                                            setShowDropDown={(value: boolean) => setShowConvoiDropDown(value)}
                                            valueSelection={(value: string) => setFormFields({...formFields, convoi: value })}
                                        />
                                        <p className="font-regular ps-2 text-sm text-red pt-0.5">{formFieldsError.convoi}</p>
                                    </div>
                                </div>
                                { convoi === "Oui" &&
                                    <div className="flex flex-col gap-3 lg:gap-2 mt-3">
                                        <div className="flex flex-col">
                                            <label className="text-base font-regular text-primary_color mb-1">Adresse/Lieu du convoi *</label>
                                            <GoogleAutoCompleteDropDown
                                                noSpaceAvailable={true}
                                                className={``}
                                                reset={resetAddress}
                                                placeholder="Choisir un(e) adresse/lieu"
                                                onHandleSelectAddress={(address: string, location: GeoPoint) => setFormFields({...formFields, addressConvoi: address, locationConvoi: location})}
                                            />
                                            <p className="font-regular ps-2 text-sm text-red pt-0.5">{formFieldsError.addressConvoi}</p>
                                        </div>
                                        <div className="flex flex-col">
                                            <label className="text-base font-regular text-primary_color mb-1">Heure du convoi/enterrement *</label>
                                            <input
                                                required
                                                placeholder="Choisir une heure"
                                                type="text"
                                                readOnly
                                                name="hourConvoy"
                                                value={hourConvoy}
                                                onClick={handleConvoyTimeDialog}
                                                className={`${hourConvoy.length === 0 ? "text-grey_light border-grey_40" : "text-primary_color_10 border-primary_color"} cursor-pointer text-base py-3 min-w-full w-full box-border placeholder-grey_light bg-grey_30 border-grey_40 outline-none focus:border-primary_color border-2 px-5 transition-colors rounded-lg lg:flex-1`}
                                            />
                                            <p className="font-regular ps-2 text-sm text-red pt-0.5">{formFieldsError.hourConvoy}</p>
                                        </div>
                                    </div>
                                }
                            </FormFieldsContainer>
                            
                            <PlainButton type="button" className="w-full lg:block lg:mt-6" text="Valider" onClick={handleSubmit}/>
                        </form>
                    </div>

                </section>
                <FooterJanaza className={``} onContactUs={() => undefined}/>
            

                <MenuOverlay
                    isMenuOpen={isMenuOpen}
                    currentUser={currentUser}
                    closeMenu={() => setMenuOpen(false)}
                    setSigningOut={handleSignOut}
                    navigateSignIn={() => navigate("/signin")}
                    navigateSignUp={() => navigate("/signup")}
                />

                { timeDialogOpen &&
                    <div onClick={handleTimeDialog} className={`fixed top-0 left-0 z-50 bg-black h-screen w-full bg-opacity-50 flex  items-center justify-center`} >
                        <div className="max-w-80 mx-5" onClick={(event) => {event.stopPropagation()}}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                                <StaticTimePicker value={hour ? dayjs(hour, "HH:mm") : dayjs()} defaultValue={dayjs()} onAccept={handleTimeDialogChange} onClose={() => setTimeDialogOpen(!timeDialogOpen)} className="rounded-md"/>
                            </LocalizationProvider>
                        </div>
                    </div>
                }

                {convoyTimeDialogOpen &&
                    <div onClick={handleConvoyTimeDialog} className={`fixed top-0 left-0 z-50 bg-black h-screen w-full bg-opacity-50 flex  items-center justify-center`} >
                        <div className="max-w-80 mx-5" onClick={(event) => {event.stopPropagation()}}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                                <StaticTimePicker value={hourConvoy ? dayjs(hourConvoy, "HH:mm") : dayjs()} defaultValue={dayjs()} onAccept={handleConvoyTimeDialogChange} onClose={() => setConvoyTimeDialogOpen(!convoyTimeDialogOpen)} className="rounded-md"/>
                            </LocalizationProvider>
                        </div>
                    </div>
                }

                { dateDialogOpen &&
                    <div onClick={handleDateDialog} className={`fixed top-0 left-0 z-50 bg-black h-screen w-full bg-opacity-50 flex items-center justify-center`} >
                        <div className="max-w-80 mx-5" onClick={(event) => {event.stopPropagation()}}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
                                <StaticDatePicker disablePast={true} minDate={dayjs()} value={date ? dayjs(date, "dd/MM/yyyy") : dayjs()} defaultValue={dayjs()} onAccept={handleDateDialogChange} onClose={() => setDateDialogOpen(!dateDialogOpen)} className="rounded-md"/>
                            </LocalizationProvider>
                        </div>
                    </div>
                }

                { (signingOut || isSending) &&
                    <LoadingOverlay className=""/>
                }
            </div>
        </div>
    );
}

function timeout(delay: number) {
    return new Promise( res => setTimeout(res, delay) );
}

export default AddPrayer;